
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Menus',
})
export default class MenusVue extends Vue {
  //public drawer :boolean = this.$store.state.menu.menuOption.drawer;;
  private responsive: boolean = false;

  //public mini: boolean = false;

  private get drawer(): boolean {
    return this.$store.state.menu.menuOption.drawer;
  }
  private set drawer(value: boolean) {
    this.$store.state.menu.menuOption.drawer = value;
  }
  private get menuActive(): number {
    return this.$store.state.menu.menuActive;
  }
  private set menuActive(value: number) {
    this.$store.state.menu.menuActive = value;
  }
  get Menus(): [object] {
    const menus = this.$store.state.menu.menus;

    // 2025-01-16 김경곤 Tmoney 사용자 경우 (사용자 ID가 t_ 또는 t1_)
    if (this.userInfo.id.startsWith('t_') || this.userInfo.id.startsWith('t1_')) {
      // 부모 메뉴와 자식 메뉴를 포함하여 필터링
      return menus
        .map((menu: any) => {
          if (menu.id === 5) {
            return {
              ...menu,
              childrens: menu.childrens.filter((child: any) => child.id === 53), // 자식 중 id: 53만 유지
            };
          }
          return null; // 다른 메뉴는 제외
        })
        .filter((menu: any) => menu !== null); // null 값 제거
    }

    // 기본 메뉴 반환
    return menus;

  }
  get mini(): any {
    return this.$store.state.menu.mini;
  }
  set mini(value: any) {
    this.$store.state.menu.mini = value;
  }
  
  get userInfo(): any {
    return this.$store.getters.getUser;
  }

  show: boolean = true;

  created(): void {
    if(this.$route.query.mode == 'modal') {
      this.show = false;
    }

    // 2025-01-16
    console.log('current user info: ', this.userInfo);
    
  }

  private mounted(): void {
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted);
  }

  movePage(item: any): void {
    this.$router.push({ name: item.target }).catch(()=>{});
  }

  private onResponsiveInverted(): void {
    if (window.innerWidth < 1000) {
      this.responsive = true;
    } else {
      this.responsive = false;
    }
  }
  private moveHome(): void {
    this.$router.push({ name: 'homeList' }).catch(()=>{});
  }
}
