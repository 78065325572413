
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import Common from '@/utils/common';
import TaxiInput from './TaxiInput.vue';
import TaxiResult from './TaxiResult.vue';
import api from '@/api'

@Component({
  name: 'TaxiList',
  components: {
    TaxiInput,
    TaxiResult,
  },
})
export default class  TaxiListVue extends Vue {
  // get pageLength(): number {
  //   return (Math.ceil(this.$store.state.common.userInfo.totalCount / this.getUser.limit ));
  // }
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  isLoading: any = false;

  expanded: any = [];
  headers: Array<Object> = [{ 
      text: '최초 접속 일자', 
      value: 'firstConnect', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '사업자명', 
      value: 'company_name', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '사업자 번호', 
      value: 'car_regnum', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '차량 유형', 
      value: 'car_type', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '시경계', 
      value: 'city_id', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '차량번호', 
      value: 'car_num', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '운전자격번호1', 
      value: 'driver_id1', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '펌웨어 릴리즈 버전', 
      value: 'fwRelease', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '데몬 버전', 
      value: 'daemonVersion', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '모뎀 번호', 
      value: 'mdn', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '시리얼 번호', 
      value: 'unit_sn', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '감속률', 
      value: 'speed_factor', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{
      text: '최근 접속일', 
      value: 'lastConnect', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '상태', 
      value: 'status', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style'
    },{ 
      text: '상세',
      value: 'action', 
      align: 'center', 
      sortable: false, 
      width: 80,
      class: 'font-weight-bold caption header-style' 
    },
  ];
  row_data: Array<Object> = []
  total: Number = 0;
  page: Number = 1;
  limit: Number = 15;
  query: Object = {};
  data: Array<Object|null> = []

  async changeFilter(filter: any): Promise<void> {
    console.log("change", filter)
    this.query = JSON.parse(JSON.stringify(filter))

    if(this.query.제품명 == "ALL"){
      this.query.제품명 = undefined
    }
    if(this.query.상태 == "ALL"){
      this.query.상태 = undefined
    }
    console.log(this.query)
    
    const res = await api.차량.COUNT(this.query)
    this.total = res.data
    this.changePage();
    
  }
  
  async changePage(num=1,limit=15): Promise<void> {
    
    // 2025-01-16
    const userId = this.$store.getters.getUser.id;
    if (userId.startsWith('t_') || userId.startsWith('t1_')) {
      this.query.city_id = 1; // Force city_id to 1 (서울) for 티머니 users
    }
    
    this.page = num;
    this.limit = limit;

    const res = await api.차량.SHOW_ALL(this.query, {offset:this.page,limit:this.limit})
    this.data = res.data.map((el) => {
      return {
        ...el,
        reg_id: el.meterUserName == null ? el.reg_id : el.meterUserName,
      }
    })
  }

  async resultDownload(): Promise<void> {
    this.isLoading = false
    let data = await api.차량.SHOW_ALL(this.query, {offset:1,limit:this.total})
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: "차량 원장 "+moment().format(),
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "택시, 원장, 차량",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    let wsData = data.data.map(el => {
  console.log(el)
      return {
        "최초접속일자": moment(el.firstConnect).subtract(9, 'h').format('YYYY-MM-DD HH:mm:ss'),
        "최근접속일자": moment(el.lastConnect).subtract(9, 'h').format('YYYY-MM-DD HH:mm:ss'),
        // "품목명": ['IEG000', 'IUG000', 'ITG000', 'IUG000', 'IKG000', 'ITG000'].find(x => x == el.firmwareVersion) != undefined ? 'KM100' : 'KM200',
        "품목명": el.firmwareVersion?.indexOf("G000") !== -1 ? 'KM100' : 'KM200',
        "시리얼 번호": el.unit_sn,
        "시경계": el.cityName,
        "차량 유형": Common.차량유형리스트()[el.car_type],
        "차량번호": el.car_num,
        "차대번호": el.car_vin,
        "사업자명": el.company_name,
        "사업자 번호": el.car_regnum,
        "운전자격번호1": el.driver_id1,
        "운전자격번호2": el.driver_id2,
        "운전자격번호3": el.driver_id3,
        "모뎀 번호": el.mdn,
        "MID(코나아이)": el.konaiMid,
        "터미널번호(코나아이)": el.konaiTid,
        "펌웨어 버전": el.firmwareVersion+'('+el.fwRelease+')',
        "데몬 버전": el.daemonVersion,
        "요금 버전": el.fareVersion,
        "요금": el.fareName,
        "감속률": el.speed_factor,
        "RPM" : el.rpm_factor,
        "벤사" : el.firmwareName,
        "미터점 이름" : el.meterUserName,
        "상태": el.status,
      }
    })
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), '단말기원장_'+moment().format()+'.xlsx');
    console.log(res)
    this.isLoading = true
  }

  register(): void {
    const TaxiRegisterVue = window.open("/#/taxi/in", "TaxiRegisterPopup", "width=1305,height=565")

    if (TaxiRegisterVue) {
      TaxiRegisterVue.focus();
      TaxiRegisterVue.onunload = () => {
        console.log("close")
        this.changePage();

      }
    } else {
      alert('팝업이 차단되어 있습니다. 팝업을 허용해주세요.');
    }
  }

  async created(): Promise<void> {
    // console.log(this.userInfo.id)

    // 2025-01-16
    const userId = this.$store.getters.getUser.id;
    if (userId.startsWith('t_') || userId.startsWith('t1_')) {
      this.query.city_id = 1; // Force city_id to 1 (서울) for 티머니 users
    }

    this.$store.commit('MENU_ACTIVE', { value: 52 });
    this.$store.commit('SET_TITLE',  { title: '차량 등록 상세', icon: 'format_list_bulleted' });

    let res = await api.차량.COUNT(this.query)
    this.total = res.data
    
    if(this.total == 0){    
      this.isLoading = true
      return
    }
    res = await api.차량.SHOW_ALL(this.query, {offset:this.page,limit:this.limit})
    console.log("SHOW_ALL: ", res)
    this.data = res.data.map((el) => {
      return {
        ...el,
        meterUserName: el.meterUserName == null ? el.reg_id : el.meterUserName,
      }
    })

    this.isLoading = true
  }
  
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
}
